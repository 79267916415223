import { LayoutProps } from "../types/layout.types";
import { useContext, useEffect, useState } from "react";
import { AuthUser, UserContext } from "../context/UserContext";
import secureLocalStorage from "react-secure-storage";
import { Button } from "src/components/ui/button";
import { DummyPage } from "src/pages/DummyPage";
import { useNavigate } from "react-router-dom";
import { UserHome } from "src/pages/user/UserHome";
import { UserReadingHistory } from "src/pages/user/UserReadingHistory";
import { UserPerformReading } from "src/pages/user/UserPerformReading";
import { AdminHome } from "src/pages/admin/AdminHome";
import { AdminReadingHistory } from "src/pages/admin/AdminReadingHistory";
import { AdminInstallationEdit } from "src/pages/admin/AdminInstallationEdit";
import { AdminInstallationAdd } from "src/pages/admin/AdminInstallationAdd";
import { AdminPerformReading } from "src/pages/admin/AdminPerformReading";
import { AdminUserList } from "src/pages/admin/AdminUserList";
import { AdminUserAdd } from "src/pages/admin/AdminUserAdd";
import { AdminUserEdit } from "src/pages/admin/AdminUserEdit";
import { AdminGroupList } from "src/pages/admin/AdminGroupList";
import { AdminGroupsEdit } from "src/pages/admin/AdminGroupsEdit";
import { AdminGroupsAdd } from "src/pages/admin/AdminGroupsAdd";
import { AdminRelationList } from "src/pages/admin/AdminRelationList";
import { AdminRelationInstallationAssociate } from "src/pages/admin/AdminRelationInstallationAssociate";
import { AdminRelationUserAssociate } from "src/pages/admin/AdminRelationUserAssociate";

const Layout = ({ page }: LayoutProps) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (userContext) {
      if (!userContext.user) {
        const strinigifiedJson: string = secureLocalStorage.getItem("state") as string;
        //console.log(strinigifiedJson);
        if (strinigifiedJson) {
          const authUser = JSON.parse(strinigifiedJson) as AuthUser;
          userContext.setUser(authUser);

          if (authUser.name === "su") {
            setIsSuperUser(true);
          }
          if (authUser.name) {
            setUsername(authUser.name);
          }
        }
      }

      if (userContext.user?.name === "su") {
        setIsSuperUser(true);
      }
      if (userContext.user) {
        setUsername(userContext.user?.name ?? "");
      }
    }
  }, []);

  //if (userContext?.user) return width < breakpoint ? <LayoutMobile page={page} /> : <LayoutDesktop page={page} />;

  console.log(page);

  if (!userContext?.user) {
    return <div>403 NOPE!</div>;
  }

  return (
    <>
      <div className="hidden flex-col md:flex">
        <div className="flex md:hidden">Mobile</div>
        <div className="hidden flex-col md:flex">
          <div className="border-b">
            <div className="flex h-16 items-center px-4">
              Bruger: {username ?? ""}
              <div className="mx-6" />
              <div className="ml-auto flex items-center space-x-4">
                {isSuperUser ? "SU" : "USER"}
                <Button
                  onClick={() => {
                    navigate("/logout");
                  }}
                >
                  Log ud
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-1 space-y-4 p-8 pt-6">
            <div className="flex items-center justify-between space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">Maass Web Portal</h2>
              {isSuperUser && (
                <div className="flex items-center space-x-2">
                  <Button
                    onClick={() => {
                      navigate("/admin/home");
                    }}
                  >
                    Installationer
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/admin/user/list");
                    }}
                  >
                    Brugere
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/admin/groups");
                    }}
                  >
                    Grupper
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/admin/relation/list");
                    }}
                  >
                    Tilknytninger
                  </Button>
                </div>
              )}
              {!isSuperUser && (
                <div className="flex items-center space-x-2">
                  {/* <Button
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    This is fun
                  </Button> */}
                </div>
              )}
            </div>
            <div>
              <div>{page === "admin-home" && <AdminHome />}</div>
              <div>{page === "admin-reading-history" && <AdminReadingHistory />}</div>
              <div>{page === "admin-installation-edit" && <AdminInstallationEdit />}</div>
              <div>{page === "admin-installation-add" && <AdminInstallationAdd />}</div>
              <div>{page === "admin-perform-reading" && <AdminPerformReading />}</div>
              <div>{page === "admin-user-list" && <AdminUserList />}</div>
              <div>{page === "admin-user-add" && <AdminUserAdd />}</div>
              <div>{page === "admin-user-edit" && <AdminUserEdit />}</div>
              <div>{page === "admin-groups" && <AdminGroupList />}</div>
              <div>{page === "admin-groups-edit" && <AdminGroupsEdit />}</div>
              <div>{page === "admin-groups-add" && <AdminGroupsAdd />}</div>
              <div>{page === "admin-relation-list" && <AdminRelationList />}</div>
              <div>{page === "admin-relation-installation-associate" && <AdminRelationInstallationAssociate />}</div>
              <div>{page === "admin-relation-user-associate" && <AdminRelationUserAssociate />}</div>

              <div>{page === "user-home" && <UserHome />}</div>
              <div>{page === "user-reading-history" && <UserReadingHistory />}</div>
              <div>{page === "user-perform-reading" && <UserPerformReading />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

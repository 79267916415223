import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card";
import { Button } from "src/components/ui/button";
import { UserContext } from "src/context/UserContext";
import logoutService from "src/services/autentication/logout.service";

export const LogoutPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  async function onClickLogout() {
    logoutService.logout();
    if (userContext) {
      userContext.setUser(null);
    }
    navigate("/");
  }

  return (
    <div>
      <div className="flex md:hidden">
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-xs translate-x-[-50%] translate-y-[-50%]">
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-5xl">Maass Web Portal</h1>
          <Button
            className="w-full"
            onClick={() => {
              onClickLogout();
            }}
          >
            Log ud
          </Button>
        </div>
      </div>
      <div className="hidden md:flex">
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%]">
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">Log ud</CardTitle>
              <CardDescription>Du er ved at logge ud af systemet</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
              </div>
            </CardContent>
            <CardFooter>
              <Button
                className="w-full"
                onClick={() => {
                  onClickLogout();
                }}
              >
                Logout
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ViewportProvider from "./providers/ViewportProvider";
import { UserContextProvider } from "./context/UserContext";
import Layout from "./layout/Layout";
import MissingPage from "./pages/missing/MissingPage";
import { LoginPage } from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";

function App() {
  return (
    <UserContextProvider>
      <ViewportProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/admin/home" element={<Layout page="admin-home" />} />
            <Route path="/admin/reading/history" element={<Layout page="admin-reading-history" />} />
            <Route path="/admin/installation/edit" element={<Layout page="admin-installation-edit" />} />
            <Route path="/admin/installation/add" element={<Layout page="admin-installation-add" />} />
            <Route path="/admin/perform/reading" element={<Layout page="admin-perform-reading" />} />
            <Route path="/admin/user/list" element={<Layout page="admin-user-list" />} />
            <Route path="/admin/user/add" element={<Layout page="admin-user-add" />} />
            <Route path="/admin/user/edit" element={<Layout page="admin-user-edit" />} />
            <Route path="/admin/groups" element={<Layout page="admin-groups" />} />
            <Route path="/admin/groups/edit" element={<Layout page="admin-groups-edit" />} />
            <Route path="/admin/groups/add" element={<Layout page="admin-groups-add" />} />
            <Route path="/admin/relation/list" element={<Layout page="admin-relation-list" />} />
            <Route path="/admin/relation/installation/assosiate" element={<Layout page="admin-relation-installation-associate" />} />
            <Route path="/admin/relation/user/assosiate" element={<Layout page="admin-relation-user-associate" />} />

            <Route path="/user/home" element={<Layout page="user-home" />} />
            <Route path="/user/reading/history" element={<Layout page="user-reading-history" />} />
            <Route path="/user/perform/reading" element={<Layout page="user-perform-reading" />} />

            <Route path="/logout" element={<LogoutPage />} />

            <Route path="*" element={<MissingPage />} />
          </Routes>
        </BrowserRouter>
      </ViewportProvider>
    </UserContextProvider>
  );
}

export default App;

import axios from "../../api/axios.private";

class GroupEditService {
  async update(id: number, name: string, description: string): Promise<number> {
    let result: number = -1;
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/group/edit";
    const data = {
      id: id,
      name: name,
      description: description,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new GroupEditService();

import { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { AdminGroupsDataTable } from "./AdminGroupsDataTable";
import { GroupItem } from "src/types/group.types";
import groupListService from "src/services/group/group.list.service";

export const AdminGroupsUIComponent = () => {
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const result = await groupListService.list();
      setGroupList(result);
      setBusy(false);
    };
    fetchData().catch(console.error);
  }, []);

  function onClickAction(to: string, item: GroupItem) {
    let url = "";

    if (to === "edit") {
      url = "/admin/groups/edit";
    }

    navigate(url, { state: item as GroupItem });
  }

  const columns: ColumnDef<GroupItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Navn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as GroupItem;

        function action(to: string, item: GroupItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => navigator.clipboard.writeText(item.id?.toString() ?? "")}>Kopier install id</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Redigér</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("edit", item)}>Gruppe</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Button
        onClick={() => {
          navigate("/admin/groups/add");
        }}
      >
        Ny gruppe
      </Button>
      <AdminGroupsDataTable columns={columns} data={groupList} />
    </div>
  );
};

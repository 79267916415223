import axios from "../../api/axios.private";

class UserDeleteService {
  async delete(id: number): Promise<number> {
    let result: number = -1;
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/user/delete";
    const data = {
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new UserDeleteService();

"use client";

import { Button } from "src/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import version from "../version.json";
import { useContext, useRef, useState } from "react";
import loginService from "src/services/autentication/login.service";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";

export const LoginPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState("");

  async function onClickLogin() {
    const eneretedUsername = usernameInputRef.current ? usernameInputRef.current.value : "";
    const eneretedPassword = passwordInputRef.current ? passwordInputRef.current.value : "";

    const loginResult = await loginService.login(eneretedUsername, eneretedPassword);
    console.log(loginResult);

    if (loginResult.access_token === "") {
      setMessage("Ugyldingt login... prøv igen");
      return;
    }

    //console.log(loginResult);

    secureLocalStorage.clear();
    secureLocalStorage.setItem("access_token", loginResult.access_token);

    if (userContext) {
      const appState = {
        id: loginResult.userid,
        name: loginResult.username,
      };

      userContext.setUser(appState);
      const json = JSON.stringify(appState);

      secureLocalStorage.setItem("state", json);
      userContext.setUser(appState);

      if (eneretedUsername === "su") {
        console.log("as admin");
        navigate("/admin/home");
      } else {
        console.log("as user");
        navigate("/user/home");
      }
    }
  }

  return (
    <div>
      <div className="flex md:hidden">
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-xs translate-x-[-50%] translate-y-[-50%]">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">Maass Web Portal</h1>
          <div className="grid gap-2">
            <Label htmlFor="username_m">Brugernavn</Label>
            <Input id="username_m" type="username" placeholder="" autoComplete="off" ref={usernameInputRef} />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password_m">Password</Label>
            <Input id="password_m" type="password" autoComplete="off" ref={passwordInputRef} />
          </div>
          <p className="text-sm text-muted-foreground">{message}</p>
          <Button
            className="w-full"
            onClick={() => {
              onClickLogin();
            }}
          >
            Login
          </Button>
          <p className="text-sm text-muted-foreground">Version: {version.version}</p>
        </div>
      </div>
      <div className="hidden md:flex">
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%]">
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">Maass Web Portal</CardTitle>
              <CardDescription>Indtast dine informationer og derefter tryk på login knappen</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Brugernavn</Label>
                <Input id="username_d" type="username_d" placeholder="" autoComplete="off" ref={usernameInputRef} />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password_d">Password</Label>
                <Input id="password_d" type="password" autoComplete="off" ref={passwordInputRef} />
              </div>
            </CardContent>
            <CardFooter>
              <Button
                className="w-full"
                onClick={() => {
                  onClickLogin();
                }}
              >
                Login
              </Button>
            </CardFooter>
            <p className="text-sm text-muted-foreground">{message}</p>
          </Card>
          <p className="text-sm text-muted-foreground">Version: {version.version}</p>
        </div>
      </div>
    </div>
  );
};

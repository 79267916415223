import { useEffect, useState } from "react";
import userListService from "src/services/user/user.list.service";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminUserListDataTable } from "./AdminUserListDataTable";
import { UserItem } from "src/types/users.types";
import { GroupItem } from "src/types/group.types";
import groupListService from "src/services/group/group.list.service";
import connectGroupUserGetBygroupService from "src/services/connect/group/user/connect.group.user.get.bygroup.service";
import { ConnectGroupUserItem } from "src/types/connect.group.user.types";
import connectGroupUserAddService from "src/services/connect/group/user/connect.group.user.add.service";
import connectGroupUserDeleteService from "src/services/connect/group/user/connect.group.user.delete.service";
import { Label } from "../../label";

export const AdminUserRelationListUIComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [group, setGroup] = useState<GroupItem>(location.state as GroupItem);
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const [connectGroupUserList, setConnectGroupUserList] = useState<ConnectGroupUserItem[]>([]);
  const [userList, setUserList] = useState<UserItem[]>([]);

  const [busy, setBusy] = useState(false);
  const [reload, setReload] = useState(false);

  function reloadPage() {
    setReload(!reload);
  }

  useEffect(() => {
    const fetchData = async () => {
      const groupList = await groupListService.list();
      setGroupList(groupList);

      const connectGroupUserList = await connectGroupUserGetBygroupService.list(group.id);
      setConnectGroupUserList(connectGroupUserList);

      const userList = await userListService.list();

      const data = userList.map((item) => {
        const found: ConnectGroupUserItem = connectGroupUserList.filter((entry) => entry.userid === item.id)[0];
        let isConnected = false;
        if (found) isConnected = true;

        let element: UserItem = {};
        element.id = item.id;
        element.username = item.username;
        element.description = item.description;
        element.connected = isConnected;

        return element;
      });
      setUserList(data);
    };
    fetchData().catch(console.error);
  }, [reload]);

  async function onClickAction(to: string, item: UserItem) {
    if (to === "connect") {
      const result = await connectGroupUserAddService.add(item.id ?? -1, group.id);
      console.log("admin create relationship between user and group complete! " + result);
    } else if (to === "disconnect") {
      const result = await connectGroupUserDeleteService.delete(item.id ?? -1, group.id);
      console.log("admin relationship between user and group removed!" + result);
    }
    reloadPage();
  }

  const columns: ColumnDef<UserItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "username",
      header: "Brugernavn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      accessorKey: "connected",
      header: "Tilknyttet",
      cell: ({ row }) => {
        const item = row.original as UserItem;
        if (item.connected) {
          return "Ja";
        } else {
          return "Nej";
        }
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as UserItem;

        function action(to: string, item: UserItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Fortag</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("connect", item)}>Tilknytning</DropdownMenuItem>
              <DropdownMenuItem onClick={() => action("disconnect", item)}>Afbryd tilknytning</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Label className="text-xl">{group.name}</Label>
      <br />
      <Label>Tilføj en eller flere brugere til genne gruppe</Label>
      <br />
      <br />
      <Button
        onClick={() => {
          navigate("/admin/relation/list");
        }}
      >
        Tilbage
      </Button>
      <AdminUserListDataTable columns={columns} data={userList} />
    </div>
  );
};

import { useEffect, useState } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { InstallationItem } from "src/types/installation.types";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminInstallationListDataTable } from "./AdminInstallationListDataTable";
import { Label } from "../../label";
import { GroupItem } from "src/types/group.types";
import installationListService from "src/services/installation/installation.list.service";
import connectGroupInstallationGetService from "src/services/connect/group/installation/connect.group.installation.get.service";
import { ConnectGroupInstallationItem } from "src/types/connect.group.installation.types";
import connectGroupInstallationAddService from "src/services/connect/group/installation/connect.group.installation.add.service";
import connectGroupInstallationDeleteService from "src/services/connect/group/installation/connect.group.installation.delete.service";

export const AdminInstallationRelationListUIComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [group, setGroup] = useState<GroupItem>(location.state as GroupItem);
  const [installationList, setInstallationList] = useState<InstallationItem[]>([]);
  const [connectGroupInstallationList, setConnectGroupInstallationList] = useState<ConnectGroupInstallationItem[]>([]);
  const [busy, setBusy] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const installationList = await installationListService.list();

      const connectGroupInstallationList = await connectGroupInstallationGetService.list(location.state.id ?? -1);
      setConnectGroupInstallationList(connectGroupInstallationList);

      const data = installationList.map((item) => {
        const found: ConnectGroupInstallationItem = connectGroupInstallationList.filter((entry) => entry.installationid === item.id)[0];
        let isConnected = false;
        if (found) isConnected = true;

        let element: InstallationItem = {};
        element.id = item.id;
        element.name = item.name;
        element.description = item.description;
        element.endpoint = item.endpoint;
        element.port = item.port;
        element.connected = isConnected;

        return element;
      });
      setInstallationList(data);

      setBusy(false);
    };
    fetchData().catch(console.error);
  }, [reload]);

  function reloadPage() {
    setReload(!reload);
  }

  async function onClickAction(to: string, item: InstallationItem) {
    if (to === "connect") {
      const result = await connectGroupInstallationAddService.add(group.id, item.id ?? -1);
      console.log("admin create relationship between installation and group complete! " + result);
    } else if (to === "disconnect") {
      const result = await connectGroupInstallationDeleteService.delete(group.id, item.id ?? -1);
      console.log("admin relationship between installation and group removed!" + result);
    }
    reloadPage();
  }

  const columns: ColumnDef<InstallationItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Navn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      accessorKey: "endpoint",
      header: "Endpoint",
    },
    {
      accessorKey: "port",
      header: "Port",
    },
    {
      accessorKey: "connected",
      header: "Tilknyttet",
      cell: ({ row }) => {
        const item = row.original as InstallationItem;
        if (item.connected) {
          return "Ja";
        } else {
          return "Nej";
        }
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as InstallationItem;

        function action(to: string, item: InstallationItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Fortag</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("connect", item)}>Tilknytning</DropdownMenuItem>
              <DropdownMenuItem onClick={() => action("disconnect", item)}>Afbryd tilknytning</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Label className="text-xl">{group.name}</Label>
      <br />
      <Label>Tilføj en eller flere installationer til genne gruppe</Label>
      <br />
      <br />
      <Button
        onClick={() => {
          navigate("/admin/relation/list");
        }}
      >
        Tilbage
      </Button>
      <AdminInstallationListDataTable columns={columns} data={installationList} />
    </div>
  );
};

import { useEffect, useState } from "react";
import userListService from "src/services/user/user.list.service";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { AdminUserListDataTable } from "./AdminUserListDataTable";
import { UserItem } from "src/types/users.types";

export const AdminUserListUIComponent = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<UserItem[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const result = await userListService.list();
      setUserList(result);
      setBusy(false);
    };
    fetchData().catch(console.error);
  }, []);

  function onClickAction(to: string, item: UserItem) {
    let url = "";

    if (to === "edit") {
      url = "/admin/user/edit";
    }

    navigate(url, { state: item as UserItem });
  }

  const columns: ColumnDef<UserItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "username",
      header: "Brugernavn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as UserItem;

        function action(to: string, item: UserItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => navigator.clipboard.writeText(item.id?.toString() ?? "")}>Kopier install id</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Redigér</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("edit", item)}>Bruger</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Button
        onClick={() => {
          navigate("/admin/user/add");
        }}
      >
        Ny bruger
      </Button>
      <AdminUserListDataTable columns={columns} data={userList} />
    </div>
  );
};

import { useContext, useEffect, useState } from "react";
import installationListService from "src/services/installation/installation.list.service";

import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { InstallationItem } from "src/types/installation.types";
import { useNavigate } from "react-router-dom";
import { UserInstallationListDataTable } from "./UserInstallationListDataTable";
import { UserContext } from "src/context/UserContext";
import profileListService from "src/services/profile/profile.list.service";

export const UserInstallationListUIComponent = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [installationList, setInstallationList] = useState<InstallationItem[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (userContext) {
      const fetchData = async () => {
        const profile = await profileListService.get(userContext.user?.id ?? -1);
        profile.assignedinstallationlist?.map((item) => {
          updateInstallationListUnique(item);
          return item;
        });
      };
      fetchData().catch(console.error);
    }
  }, [userContext, userContext?.user]);

  function uniqueById(items: InstallationItem[]) {
    const set = new Set();
    return items.filter((item) => {
      const isDuplicate = set.has(item.id);
      set.add(item.id);
      return !isDuplicate;
    });
  }

  const updateInstallationListUnique = (newItem: InstallationItem) => {
    setInstallationList((prev) => {
      return uniqueById([...prev, newItem]);
    });
  };

  function onClickAction(to: string, item: InstallationItem) {
    let url = "";

    if (to === "view-history") {
      url = "/user/reading/history";
    }
    if (to === "perform-reading") {
      url = "/user/perform/reading";
    }

    navigate(url, { state: item as InstallationItem });
  }

  const columns: ColumnDef<InstallationItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Navn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      accessorKey: "endpoint",
      header: "Endpoint",
    },
    {
      accessorKey: "port",
      header: "Port",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as InstallationItem;

        function action(to: string, item: InstallationItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Aflæsninger</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("view-history", item)}>Historik</DropdownMenuItem>
              <DropdownMenuItem onClick={() => action("perform-reading", item)}>Aflæs nu</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <UserInstallationListDataTable columns={columns} data={installationList} />
    </div>
  );
};

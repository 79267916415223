import axios from "../../api/axios.private";
import { ReadingItem } from "../../types/reading.types";

class ReadingEndpointGetService {
  async get(endpoint: string, port: string): Promise<ReadingItem[]> {
    let result: ReadingItem[] = [];
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/reading/enpoint/get";

    const data = {
      endpoint: endpoint,
      port: port,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new ReadingEndpointGetService();

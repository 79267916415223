import { Label } from "src/components/ui/label";

export const MissingPage = () => {
  return (
    <div>
      <Label>Uppps! Missing page</Label>
    </div>
  );
};

export default MissingPage;

import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { ReadingItem } from "src/types/reading.types";
import readingEndpointGetService from "src/services/reading/reading.endpoint.get.service";
import { Label } from "@radix-ui/react-label";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Button } from "../button";
import { useNavigate } from "react-router-dom";

export interface ChartDataItem {
  id?: number;
  organ?: number;
  narthex?: number;
  choir?: number;
  plainowen?: number;
  nave?: number;
}

export const AdminReadingUIComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [item, setItem] = useState<ReadingItem>(location.state as ReadingItem);
  const [readings, setReadings] = useState<ChartDataItem[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await readingEndpointGetService.get(item.endpoint ?? "", item.port ?? "");
      setCount(result.length);
      const last = result.slice(result.length - 20, result.length);

      last.map((reading, index) => {
        const chartDataItem: ChartDataItem = {
          id: index,
          organ: reading.organ ?? 0,
          narthex: reading.narthex ?? 0,
          choir: reading.choir ?? 0,
          plainowen: reading.plainowen ?? 0,
          nave: reading.nave ?? 0,
        };
        updateUnique(chartDataItem);

        return reading;
      });
    };
    fetchData().catch(console.error);
  }, []);

  const updateUnique = (newItem: ChartDataItem) => {
    setReadings((prev) => {
      return uniqueById([...prev, newItem]);
    });
  };

  function uniqueById(items: ChartDataItem[]) {
    const set = new Set();
    return items.filter((item) => {
      const isDuplicate = set.has(item.id);
      set.add(item.id);
      return !isDuplicate;
    });
  }

  function onClickBack() {
    navigate("/admin/home");
  }

  return (
    <div className="flex flex-col items-left justify-between space-y-2">
      <Label>Historie</Label>
      <Label>{`${location.state.endpoint}:${location.state.port}`}</Label>
      <Label>Der er {count} aflæsnigner i alt. Viser de sidste 20 nedenunder.</Label>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={500}
          height={300}
          data={readings}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="organ" stroke="hsl(293, 70%, 50%)" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="narthex" stroke="hsl(54, 70%, 50%)" />
          <Line type="monotone" dataKey="choir" stroke="hsl(7, 70%, 50%)" />
          <Line type="monotone" dataKey="plainowen" stroke="hsl(99, 70%, 50%)" />
          <Line type="monotone" dataKey="nave" stroke="hsl(317, 70%, 50%)" />
        </LineChart>
      </ResponsiveContainer>
      <Button
        onClick={() => {
          onClickBack();
        }}
      >
        Tilbage
      </Button>
    </div>
  );
};

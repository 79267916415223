import axios from "../../api/axios.private";
import { LoginResult } from "../../types/login.types";

class LoginService {
  async login(username: string, password: string): Promise<LoginResult> {
    let result: LoginResult = { username: "", access_token: "", userid: 0 };

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/autentication/login";
    const data = {
      username: username,
      password: password,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        const valid1 = "access_token" in res.data;
        const valid2 = "userid" in res.data;
        if (valid1 && valid2) {
          result = res.data;
        }
      })
      //.catch((err) => console.log(err));
      .catch((err) => {
        console.log(err);
      });

    return result;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LoginService();

import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import installationAddService from "src/services/installation/installation.add.service";

export const AdminInstallationAdd = () => {
  const navigate = useNavigate();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);
  const endpointInputRef = useRef<HTMLInputElement>(null);
  const portInputRef = useRef<HTMLInputElement>(null);
  const versionInputRef = useRef<HTMLInputElement>(null);

  const [uiId, setUiId] = useState<number>(-1);
  const [uiName, setUIName] = useState("");
  const [uiDescription, setUIDescription] = useState("");
  const [uiEndpoint, setUIEndpoint] = useState("");
  const [uiPort, setUIPort] = useState("");
  const [uiVersion, setUIVersion] = useState("");

  async function onClickCreate() {
    const name = nameInputRef.current ? nameInputRef.current.value : "";
    const description = descriptionInputRef.current ? descriptionInputRef.current.value : "";
    const endpoint = endpointInputRef.current ? endpointInputRef.current.value : "";
    const port = portInputRef.current ? portInputRef.current.value : "";
    const version = versionInputRef.current ? versionInputRef.current.value : "";

    const result = await installationAddService.add(name, description, endpoint, port, version);

    console.log("installation added. status code " + result);

    navigate("/admin/home");
  }

  return (
    <div>
      <div className="grid w-full max-w left-[50%] top-[50%] z-50">
        <div>
          <Label htmlFor="name">Navn</Label>
          <Input
            id="name"
            type="text"
            value={uiName}
            onChange={(e) => {
              setUIName(e.target.value);
            }}
            required
            placeholder=""
            autoComplete="off"
            ref={nameInputRef}
          />
        </div>
        <div>
          <Label htmlFor="description">Beskrivelse</Label>
          <Input
            id="description"
            type="text"
            value={uiDescription}
            onChange={(e) => {
              setUIDescription(e.target.value);
            }}
            required
            autoComplete="off"
            ref={descriptionInputRef}
          />
        </div>
        <div>
          <Label htmlFor="endpoint">Endpoint</Label>
          <Input
            id="endpoint"
            type="text"
            value={uiEndpoint}
            onChange={(e) => {
              setUIEndpoint(e.target.value);
            }}
            required
            autoComplete="off"
            ref={endpointInputRef}
          />
        </div>
        <div>
          <Label htmlFor="port">Port</Label>
          <Input
            id="port"
            type="text"
            value={uiPort}
            onChange={(e) => {
              setUIPort(e.target.value);
            }}
            required
            autoComplete="off"
            ref={portInputRef}
          />
        </div>
        <div>
          <Label htmlFor="version">Version</Label>
          <Input
            id="version"
            type="text"
            value={uiVersion}
            onChange={(e) => {
              setUIVersion(e.target.value);
            }}
            required
            autoComplete="off"
            ref={versionInputRef}
          />
        </div>
        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickCreate();
          }}
        >
          Opret installation
        </Button>
      </div>
    </div>
  );
};

import axios from "../../../../api/axios.private";
import { ConnectGroupUserItem } from "../../../../types/connect.group.user.types";

class ConnectGroupUserGetByGroupService {
  async list(id: number): Promise<ConnectGroupUserItem[]> {
    let result: ConnectGroupUserItem[] = [];
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/connect/group/user/bygroup";
    const data = {
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new ConnectGroupUserGetByGroupService();

import axios from "../../../../api/axios.private";

class ConnectGroupInstallationAddService {
  async add(groupid: number, installationid: number): Promise<number> {
    let result: number = -1;
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/connect/group/installation/add";
    const data = {
      groupid: groupid,
      installationid: installationid,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new ConnectGroupInstallationAddService();

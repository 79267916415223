import axios from "../../api/axios.private";
import { GroupItem } from "../../types/group.types";

class GroupListService {
  async list(): Promise<GroupItem[]> {
    let result: GroupItem[] = [];
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/group/list";

    await axios
      .get(url, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new GroupListService();

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";
import apiMaassV1GetService from "src/services/api/api.maass.v1.get.service";
import { MaassAPIGetResponse } from "src/types/api.maass.v1.get.response.types";

export const AdminPerformReading = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [uiId, setUiId] = useState<number>(location.state.id ?? 0);
  const [uiName, setUIName] = useState(location.state.name ?? "");
  const [uiDescription, setUIDescription] = useState(location.state.description ?? "");
  const [uiEndpoint, setUIEndpoint] = useState(location.state.endpoint ?? "");
  const [uiPort, setUIPort] = useState(location.state.port ?? "");
  const [uiVersion, setUIVersion] = useState(location.state.version ?? "");

  const [busy, setBusy] = useState(false);
  const [reading, setReading] = useState<MaassAPIGetResponse>({});

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const result = await apiMaassV1GetService.get(uiEndpoint ?? "", uiPort ?? "", uiVersion ?? "");
      setReading(result);
      setBusy(false);
    };
    fetchData().catch(console.error);
  }, []);

  function onClickBack() {
    navigate("/admin/home");
  }

  return (
    <div>
      {busy ? (
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-xl translate-x-[-50%] translate-y-[-50%]">
          <Label className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-2xl">
            Installation {uiName} - {uiEndpoint}:{uiPort}
          </Label>
          <Label className="space-x-2">Henter fra fra systemet ... vent venligst</Label>
        </div>
      ) : (
        <div>
          <div>
            <Label className="space-x-2">
              Installation {uiName} - {uiEndpoint}:{uiPort}
            </Label>
            <br />
            <br />
            <Button
              onClick={() => {
                onClickBack();
              }}
            >
              Tilbage
            </Button>
            <br />
            <br />
            <table className="mytable table-auto w-full">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Interface version</td>
                  <td>{`${reading.interfaceversion}`}</td>
                </tr>
                <tr>
                  <td>System time</td>
                  <td>{`${reading.systemtime}`}</td>
                </tr>
                <tr>
                  <td>Organ</td>
                  <td>{`${reading.organ}`}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{`${reading.status}`}</td>
                </tr>
                <tr>
                  <td>Syncron</td>
                  <td>{`${reading.syncron}`}</td>
                </tr>
              </tbody>
            </table>

            <table className="mytable table-auto w-full">
              <thead>
                <tr>
                  <th>Sensor</th>
                  <th>Current</th>
                  <th>Thermostat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Choir</td>
                  <td>{`${reading.temperatures?.current?.choir}`}</td>
                  <td>{`${reading.temperatures?.thermostat?.choir}`}</td>
                </tr>
                <tr>
                  <td>Narthex</td>
                  <td>{`${reading.temperatures?.current?.narthex}`}</td>
                  <td>{`${reading.temperatures?.thermostat?.narthex}`}</td>
                </tr>
                <tr>
                  <td>Nave</td>
                  <td>{`${reading.temperatures?.current?.nave}`}</td>
                  <td>{`${reading.temperatures?.thermostat?.nave}`}</td>
                </tr>
                <tr>
                  <td>Organ</td>
                  <td>{`${reading.temperatures?.current?.organ}`}</td>
                  <td>{`${reading.temperatures?.thermostat?.organ}`}</td>
                </tr>
                <tr>
                  <td>Plainowen</td>
                  <td>{`${reading.temperatures?.current?.plainowen}`}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Basicheating</td>
                  <td></td>
                  <td>{`${reading.temperatures?.thermostat?.basicheating}`}</td>
                </tr>
              </tbody>
            </table>

            <table className="mytable table-auto w-full">
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Start</th>
                  <th>Stop</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {reading &&
                  reading.programs?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{`${item.id}`}</td>
                        <td>{`${item.startHour?.toString().padStart(2, "0")}:${item.startMinute?.toString().padStart(2, "0")}`}</td>
                        <td>{`${item.stopHour?.toString().padStart(2, "0")}:${item.stopMinute?.toString().padStart(2, "0")}`}</td>
                        <td>{`${item.state}`}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

import axios from "../../api/axios.private";

class RefreshService {
  async refresh(): Promise<string> {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/api/v1/autentication/refresh.php";

    return await axios
      .get(url, config)
      .then((res) => {
        const valid = "access_token" in res.data;
        if (valid) {
          return res.data.access_token;
        }
        return "";
      })
      .catch((err) => console.log(err));
  }
}

const instance = new RefreshService();
export default instance;

import { useEffect, useState } from "react";
import installationListService from "src/services/installation/installation.list.service";

import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { InstallationItem } from "src/types/installation.types";
import { useNavigate } from "react-router-dom";
import { AdminInstallationListDataTable } from "./AdminInstallationListDataTable";

export const AdminInstallationListUIComponent = () => {
  const navigate = useNavigate();
  const [installationList, setInstallationList] = useState<InstallationItem[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const result = await installationListService.list();
      setInstallationList(result);
      setBusy(false);
    };
    fetchData().catch(console.error);
  }, []);

  function onClickAction(to: string, item: InstallationItem) {
    let url = "";

    if (to === "view-history") {
      url = "/admin/reading/history";
    }
    if (to === "perform-reading") {
      url = "/admin/perform/reading";
    }
    if (to === "edit-installation") {
      url = "/admin/installation/edit";
    }
    if (to === "edit-remove") {
      url = "";
    }

    navigate(url, { state: item as InstallationItem });
  }

  const columns: ColumnDef<InstallationItem>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Navn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      accessorKey: "endpoint",
      header: "Endpoint",
    },
    {
      accessorKey: "port",
      header: "Port",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as InstallationItem;

        function action(to: string, item: InstallationItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => navigator.clipboard.writeText(item.id?.toString() ?? "")}>Kopier install id</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Aflæsninger</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("view-history", item)}>Historik</DropdownMenuItem>
              <DropdownMenuItem onClick={() => action("perform-reading", item)}>Aflæs nu</DropdownMenuItem>
              <DropdownMenuLabel>Redigér</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("edit-installation", item)}>Installation</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Button
        onClick={() => {
          navigate("/admin/installation/add");
        }}
      >
        Ny installation
      </Button>
      <AdminInstallationListDataTable columns={columns} data={installationList} />
    </div>
  );
};

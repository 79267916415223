import React, { PropsWithChildren } from "react";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }: PropsWithChildren) => {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
};

export default ViewportProvider;

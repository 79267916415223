import { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "src/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { GroupItem } from "src/types/group.types";
import groupListService from "src/services/group/group.list.service";
import { AdminGroupsDataTable } from "./AdminGroupsDataTable";
import { Label } from "../../label";

export const AdminGroupsRelationUIComponent = () => {
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      const result = await groupListService.list();
      setGroupList(result);
      setBusy(false);
    };
    fetchData().catch(console.error);
  }, []);

  function onClickAction(to: string, item: GroupItem) {
    let url = "";

    if (to === "installation") {
      url = "/admin/relation/installation/assosiate";
    }
    if (to === "user") {
      url = "/admin/relation/user/assosiate";
    }

    navigate(url, { state: item as GroupItem });
  }

  const columns: ColumnDef<GroupItem>[] = [
    {
      accessorKey: "name",
      header: "Navn",
    },
    {
      accessorKey: "description",
      header: "Beskrivelse",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const item = row.original as GroupItem;

        function action(to: string, item: GroupItem) {
          onClickAction(to, item);
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>:::
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Tilknyt</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => action("installation", item)}>Installation</DropdownMenuItem>
              <DropdownMenuItem onClick={() => action("user", item)}>Bruger</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Label className="text-xl">Sammenknytning mellem brugere og installationer</Label>
      <br />
      <Label>En bruger vi opleve sammenkædningen som en lang liste af installationer. En sammenknytning indeholder både bruger og installationer.</Label>
      <AdminGroupsDataTable columns={columns} data={groupList} />
    </div>
  );
};

import axios from "../../api/axios.private";
import { MaassAPIGetResponse } from "../../types/api.maass.v1.get.response.types";

class ApiMaassV1GetService {
  async get(endpoint: string, port: string, version: string): Promise<MaassAPIGetResponse> {
    let result: MaassAPIGetResponse = {};

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/api/get";
    const data = {
      endpoint: endpoint,
      port: port,
      version: version,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(result);
      })
      //.catch((err) => console.log(err));
      .catch((err) => {});

    return result;
  }
}

const instance = new ApiMaassV1GetService();
export default instance;

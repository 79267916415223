import axios from "../../api/axios.private";

class UserEditService {
  async update(id: number, username: string, password: string, description: string): Promise<number> {
    let result: number = -1;
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/user/edit";
    const data = {
      id: id,
      username: username,
      password: password,
      description: description,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new UserEditService();

import axios from "../../../../api/axios.private";
import { ConnectGroupInstallationItem } from "../../../../types/connect.group.installation.types";

class ConnectGroupInstallationGetService {
  async list(id: number): Promise<ConnectGroupInstallationItem[]> {
    let result: ConnectGroupInstallationItem[] = [];
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/connect/group/installation/get";
    const data = {
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new ConnectGroupInstallationGetService();

import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import groupEditService from "src/services/group/group.edit.service";
import groupDeleteService from "src/services/group/group.delete.service";

export const AdminGroupsEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [id, setId] = useState<number>(location.state?.id ?? -1);
  const [uiName, setUIName] = useState(location.state?.name ?? "");
  const [uiDescription, setUIDescription] = useState(location.state?.description ?? "");

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  async function onClickUpdate() {
    const name = nameInputRef.current ? nameInputRef.current.value : "";
    const description = descriptionInputRef.current ? descriptionInputRef.current.value : "";

    const result = await groupEditService.update(id, name, description);

    console.log("group updated. status code " + result);

    navigate("/admin/groups");
  }

  async function onClickDelete() {
    const result = await groupDeleteService.delete(id);

    console.log("group deleted. status code " + result);

    navigate("/admin/groups");
  }

  return (
    <div>
      <div className="grid w-full max-w left-[50%] top-[50%] z-50">
        <div>
          <Label htmlFor="username">Name</Label>
          <Input
            id="name"
            type="text"
            value={uiName}
            onChange={(e) => {
              setUIName(e.target.value);
            }}
            required
            placeholder=""
            autoComplete="off"
            ref={nameInputRef}
          />
        </div>
        <div>
          <Label htmlFor="description">Beskrivelse</Label>
          <Input
            id="description"
            type="text"
            value={uiDescription}
            onChange={(e) => {
              setUIDescription(e.target.value);
            }}
            required
            autoComplete="off"
            ref={descriptionInputRef}
          />
        </div>

        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickUpdate();
          }}
        >
          Opdatér gruppe
        </Button>
        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickDelete();
          }}
        >
          Slet gruppe
        </Button>
      </div>
    </div>
  );
};

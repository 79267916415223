import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import groupAddService from "src/services/group/group.add.service";

export const AdminGroupsAdd = () => {
  const navigate = useNavigate();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  const [uiName, setUIName] = useState("");
  const [uiDescription, setUIDescription] = useState("");

  async function onClickCreate() {
    const username = nameInputRef.current ? nameInputRef.current.value : "";
    const description = descriptionInputRef.current ? descriptionInputRef.current.value : "";

    const result = await groupAddService.add(username, description);

    console.log("group added. status code " + result);

    navigate("/admin/groups");
  }

  return (
    <div>
      <div className="grid w-full max-w left-[50%] top-[50%] z-50">
        <div>
          <Label htmlFor="name">Navn</Label>
          <Input
            id="name"
            type="text"
            value={uiName}
            onChange={(e) => {
              setUIName(e.target.value);
            }}
            required
            placeholder=""
            autoComplete="off"
            ref={nameInputRef}
          />
        </div>
        <div>
          <Label htmlFor="description">Beskrivelse</Label>
          <Input
            id="description"
            type="text"
            value={uiDescription}
            onChange={(e) => {
              setUIDescription(e.target.value);
            }}
            required
            autoComplete="off"
            ref={descriptionInputRef}
          />
        </div>

        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickCreate();
          }}
        >
          Opret gruppe
        </Button>
      </div>
    </div>
  );
};

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import userAddService from "src/services/user/user.add.service";

export const AdminUserAdd = () => {
  const navigate = useNavigate();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  const [uiUsername, setUIUsername] = useState("");
  const [uiPassword, setUIPassword] = useState("");
  const [uiDescription, setUIDescription] = useState("");

  async function onClickCreate() {
    const username = usernameInputRef.current ? usernameInputRef.current.value : "";
    const password = passwordInputRef.current ? passwordInputRef.current.value : "";
    const description = descriptionInputRef.current ? descriptionInputRef.current.value : "";

    const result = await userAddService.add(username, password, description);

    console.log("user added. status code " + result);

    navigate("/admin/user/list");
  }

  return (
    <div>
      <div className="grid w-full max-w left-[50%] top-[50%] z-50">
        <div>
          <Label htmlFor="username">Brugernavn</Label>
          <Input
            id="username"
            type="text"
            value={uiUsername}
            onChange={(e) => {
              setUIUsername(e.target.value);
            }}
            required
            placeholder=""
            autoComplete="off"
            ref={usernameInputRef}
          />
        </div>
        <div>
          <Label htmlFor="endpoint">Password</Label>
          <Input
            id="endpoint"
            type="password"
            value={uiPassword}
            onChange={(e) => {
              setUIPassword(e.target.value);
            }}
            required
            autoComplete="off"
            ref={passwordInputRef}
          />
        </div>
        <div>
          <Label htmlFor="description">Beskrivelse</Label>
          <Input
            id="description"
            type="text"
            value={uiDescription}
            onChange={(e) => {
              setUIDescription(e.target.value);
            }}
            required
            autoComplete="off"
            ref={descriptionInputRef}
          />
        </div>

        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickCreate();
          }}
        >
          Opret bruger
        </Button>
      </div>
    </div>
  );
};

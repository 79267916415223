import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import installationDeleteService from "src/services/installation/installation.delete.service";
import installationEditService from "src/services/installation/installation.edit.service";

export const AdminInstallationEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);
  const endpointInputRef = useRef<HTMLInputElement>(null);
  const portInputRef = useRef<HTMLInputElement>(null);
  const versionInputRef = useRef<HTMLInputElement>(null);

  const [uiId, setUiId] = useState<number>(location.state.id ?? 0);
  const [uiName, setUIName] = useState(location.state.name ?? "");
  const [uiDescription, setUIDescription] = useState(location.state.description ?? "");
  const [uiEndpoint, setUIEndpoint] = useState(location.state.endpoint ?? "");
  const [uiPort, setUIPort] = useState(location.state.port ?? "");
  const [uiVersion, setUIVersion] = useState(location.state.version ?? "");

  async function onClickUpdate() {
    const name = nameInputRef.current ? nameInputRef.current.value : "";
    const description = descriptionInputRef.current ? descriptionInputRef.current.value : "";
    const endpoint = endpointInputRef.current ? endpointInputRef.current.value : "";
    const port = portInputRef.current ? portInputRef.current.value : "";
    const version = versionInputRef.current ? versionInputRef.current.value : "";

    const result = await installationEditService.update(uiId, name, description, endpoint, port, version);

    console.log("installation edit. status code " + result);

    navigate("/admin/home");
  }

  async function onClickDelete() {
    const result = await installationDeleteService.delete(uiId);
    console.log("installation delete. status code " + result);
    navigate("/admin/home");
  }

  return (
    <div>
      <div className="grid w-full max-w left-[50%] top-[50%] z-50">
        <div>
          <Label htmlFor="name">Navn</Label>
          <Input
            id="name"
            type="text"
            value={uiName}
            onChange={(e) => {
              setUIName(e.target.value);
            }}
            required
            placeholder=""
            autoComplete="off"
            ref={nameInputRef}
          />
        </div>
        <div>
          <Label htmlFor="description">Beskrivelse</Label>
          <Input
            id="description"
            type="text"
            value={uiDescription}
            onChange={(e) => {
              setUIDescription(e.target.value);
            }}
            required
            autoComplete="off"
            ref={descriptionInputRef}
          />
        </div>
        <div>
          <Label htmlFor="endpoint">Endpoint</Label>
          <Input
            id="endpoint"
            type="text"
            value={uiEndpoint}
            onChange={(e) => {
              setUIEndpoint(e.target.value);
            }}
            required
            autoComplete="off"
            ref={endpointInputRef}
          />
        </div>
        <div>
          <Label htmlFor="port">Port</Label>
          <Input
            id="port"
            type="text"
            value={uiPort}
            onChange={(e) => {
              setUIPort(e.target.value);
            }}
            required
            autoComplete="off"
            ref={portInputRef}
          />
        </div>
        <div>
          <Label htmlFor="version">Version</Label>
          <Input
            id="version"
            type="text"
            value={uiVersion}
            onChange={(e) => {
              setUIVersion(e.target.value);
            }}
            required
            autoComplete="off"
            ref={versionInputRef}
          />
        </div>
        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickUpdate();
          }}
        >
          Opdatér
        </Button>
        <br />
        <Button
          className="w-full"
          onClick={() => {
            onClickDelete();
          }}
        >
          Slet
        </Button>
      </div>
    </div>
  );
};

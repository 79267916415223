import axios from "../../api/axios.private";
import { UserItem } from "../../types/users.types";

class UserListService {
  async list(): Promise<UserItem[]> {
    let result: UserItem[] = [];
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/su/user/list";

    await axios
      .get(url, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new UserListService();
